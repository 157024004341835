require('./bootstrap')
require('./filters')

require("../../vendor/js/jquery.waypoints.min")

require('./pages/all')
require('./partials/all')

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

window.eventHub = new Vue()

import Base from './components/base'
import SocialLogin from './components/social-login'
import VueLazyLoad from 'vue-lazyload'
import VueMask from 'v-mask'

import { store } from '../../shared/js/state/SearchboxState'

let isRetina = (
    window.devicePixelRatio > 1 ||
    (window.matchMedia && window.matchMedia("(-webkit-min-device-pixel-ratio: 1.5),(-moz-min-device-pixel-ratio: 1.5),(min-device-pixel-ratio: 1.5)").matches)
);

Vue.use(VueLazyLoad, {
    filter: {
        progressive(listener, options) {
            let hasRetinaVersion = (listener.el.getAttribute('lazy-retina') === 'true')

            if (isRetina && hasRetinaVersion) {
                let url = listener.src
                let directory = url.substring(0, url.lastIndexOf('/'));
                let filename = url.substring(url.lastIndexOf('/') + 1);
                listener.src = directory + '/2x/' + filename
            }
        },
    }
})

Vue.use(VueMask);

Vue.component('searchbox', require('./components/Searchbox/Searchbox.vue').default);
Vue.component('searchbox-inline', require('./components/Searchbox/SearchboxInline.vue').default);
Vue.component('searchbox-hotel', require('./components/Searchbox/SearchboxHotel.vue').default);
Vue.component('subscribe', require('./components/subscribe.vue').default);

Vue.mixin({
    methods: {
        trackEventGlobal(category, action = '', label = '') {
            dataLayer.push({
                'event': 'GaEvent',
                'EventCategory': category,
                'EventAction': action,
                'EventLabel': label
            });
        },

        // ToDO: can be replaced with lot.baseRateStr in 100 days after release
        getBaseRate(lot) {
            return typeof lot.baseRateStr !== 'undefined'
                ? lot.baseRateStr
                : '$' + lot.baseRate;
        },
    }
});

new Vue({
    store: store,
    mixins: [Base, SocialLogin],

    mounted() {
        this.initBase()
        this.initSocialLoginButtons()
	    setTimeout(() => {
		    if (typeof initLoad === "function") {
			    initLoad()
		    }
	    }, 500)
    },
}).$mount('#app');
